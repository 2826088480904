var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c(
        "v-btn",
        {
          staticClass: "my-3",
          attrs: { color: "primary" },
          on: { click: _vm.closeDetail },
        },
        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
        1
      ),
      this.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !this.api.isLoading
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "secondary text-h6 indigo white--text" },
                [_vm._v(" " + _vm._s(_vm.localCompanies.name) + " ")]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: { label: "company name" },
                                model: {
                                  value: _vm.localCompanies.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localCompanies, "name", $$v)
                                  },
                                  expression: "localCompanies.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-row"),
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-inner-icon": "mdi-map-marker",
                              clearable: "",
                              label: "address",
                            },
                            model: {
                              value: _vm.localCompanies.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.localCompanies, "address", $$v)
                              },
                              expression: "localCompanies.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: { label: "industry" },
                                model: {
                                  value: _vm.localCompanies.industry,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localCompanies,
                                      "industry",
                                      $$v
                                    )
                                  },
                                  expression: "localCompanies.industry",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  label: "company size",
                                  min: "0",
                                },
                                model: {
                                  value: _vm.localCompanies.company_size,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localCompanies,
                                      "company_size",
                                      $$v
                                    )
                                  },
                                  expression: "localCompanies.company_size",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-auto" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: "primary",
                                loading: this.api.isLoading,
                              },
                              on: { click: _vm.validateInput },
                            },
                            [_vm._v(" Save ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeDialogEvent")
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }